import { NgModule } from "@angular/core";
import { S25EventStatesComponent } from "./s25.event.states.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

@NgModule({
    imports: [CommonModule, FormsModule, S25LoadingInlineModule],
    exports: [S25EventStatesComponent],
    providers: [S25EventStatesComponent],
    declarations: [S25EventStatesComponent],
})
export class S25EventStatesModule {}
