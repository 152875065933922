import { jSith } from "../../../util/jquery-replacement";
import { Event } from "../../../pojo/Event";

export class EventFormConflictRepoService {
    public static addConflictByModel(formModel: Event.Form, conflict: any) {
        return EventFormConflictRepoService.addConflict(
            formModel.eventId,
            formModel.profileId,
            conflict.candidateId,
            conflict.itemTypeId,
            conflict.occUUID,
        );
    }

    public static addConflict(eventId: number, profileId: number, itemId: number, itemTypeId: number, occUUID: string) {
        addRepo(eventId, profileId)[conflictKey(itemId, itemTypeId, occUUID)] = true;
    }

    public static removeConflictByObjectModel(formModel: Event.Form, item: any) {
        return EventFormConflictRepoService.removeConflictByObject(
            formModel.eventId,
            formModel.profileId,
            item.itemId,
            item.itemTypeId,
        );
    }

    public static removeConflictByObject(eventId: number, profileId: number, itemId: number, itemTypeId: number) {
        let conflictObj = addRepo(eventId, profileId);
        jSith.forEach(conflictObj, function (key, val) {
            if (key.startsWith(itemId + "&" + itemTypeId + "&")) {
                delete conflictObj[key];
            }
        });
    }

    public static removeConflictByOccModel(formModel: Event.Form, occ: any) {
        return EventFormConflictRepoService.removeConflictByOcc(formModel.eventId, formModel.profileId, occ.uuid);
    }

    public static removeConflictByOcc(eventId: number, profileId: number, occUUID: string) {
        let conflictObj = addRepo(eventId, profileId);
        jSith.forEach(conflictObj, function (key, val) {
            if (key.endsWith("&" + occUUID)) {
                delete conflictObj[key];
            }
        });
    }

    public static removeConflictByObjOccModel(formModel: Event.Form, item: any, occ: any) {
        return EventFormConflictRepoService.removeConflictByObjOcc(
            formModel.eventId,
            formModel.profileId,
            item.itemId,
            item.itemTypeId,
            occ.uuid,
        );
    }

    public static removeConflictByObjOcc(
        eventId: number,
        profileId: number,
        itemId: number,
        itemTypeId: number,
        occUUID: string,
    ) {
        var conflictObj = addRepo(eventId, profileId);
        jSith.forEach(conflictObj, function (key, val) {
            if (key === conflictKey(itemId, itemTypeId, occUUID)) {
                delete conflictObj[key];
            }
        });
    }

    public static getRepo(eventId: number, profileId: number) {
        return addRepo(eventId, profileId);
    }

    public static hasConflictsModel(formModel: Event.Form) {
        return EventFormConflictRepoService.hasConflicts(formModel.eventId, formModel.profileId);
    }

    public static hasConflicts(eventId: number, profileId: number) {
        var hasConflictsBool = false;
        jSith.forEach(addRepo(eventId, profileId), function (key, val) {
            hasConflictsBool = true;
        });
        return hasConflictsBool;
    }
}

let repo: any = {};

function repoKey(eventId: number, profileId: number) {
    return eventId + "&" + profileId;
}

function addRepo(eventId: number, profileId: number) {
    if (!repo[repoKey(eventId, profileId)]) {
        repo[repoKey(eventId, profileId)] = {};
    }
    return repo[repoKey(eventId, profileId)];
}

function conflictKey(itemId: number, itemTypeId: number, occUUID: string) {
    return itemId + "&" + itemTypeId + "&" + occUUID;
}
