import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { ErrorsService, UncoveredUrl } from "../../services/errors.service";
import { Table } from "../s25-table/Table";
import { jSith } from "../../util/jquery-replacement";
import Cell = Table.Cell;
import { S25Util } from "../../util/s25-util";
import { GenericTableFadePreviewComponent } from "../s25-table/generics/generic.table.fade.preview.component";
import { S25TableModule } from "../s25-table/s25.table.module";

@TypeManagerDecorator("s25-ng-errors")
@Component({
    standalone: true,
    imports: [S25TableModule],
    selector: "s25-ng-errors",
    template: `
        <div class="ngBold">Last 100 Errors Table</div>
        <s25-ng-table
            [caption]="'Errors'"
            [unlimitedWidth]="true"
            [columnSortable]="false"
            [dataSource]="dataSource"
            [hasTotalRowCount]="true"
            [hasRefresh]="true"
        ></s25-ng-table>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ErrorsComponent implements OnInit {
    dataSource: Table.Unpaginated;

    ngOnInit() {
        this.dataSource = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "uncoveredUrlId",
                    header: "Id",
                },
                {
                    id: "createDt",
                    header: "Create Date",
                },
                {
                    id: "component",
                    header: "Component",
                },
                {
                    id: "errProcedure",
                    header: "Procedure",
                },
                {
                    id: "reason",
                    header: "Reason",
                },
                {
                    id: "detail",
                    header: "Detail",
                    maxWidth: 400,
                },
                {
                    id: "meta",
                    header: "Meta",
                    maxWidth: 400,
                },
            ],
        };
    }

    getRows = async () => {
        let rows: Table.Row[] = [];
        let errors = await ErrorsService.getErrors();
        if (errors?.length) {
            jSith.forEach(errors, (idx, error: UncoveredUrl) => {
                const cells: Table.Row["cells"] = {};
                for (let i = 0; i < this.dataSource.columns.length; i++) {
                    let column = this.dataSource.columns[i];
                    let cell: Cell = {};

                    if (column.id === "component") {
                        cell.text = this.addBreakpoints(error.component?.componentName);
                    } else {
                        cell.text = this.addBreakpoints(error[column.id]);
                    }

                    if (column.id === "errProcedure") {
                        cell.text = this.addBreakpoints(error.errProcedure || error.requestLog?.procedureName) || "";
                    } else if (column.id === "meta") {
                        error.meta = error.meta === "T_REQUEST_LOG" ? "" : error.meta;
                        let text = error.meta || error.requestLog?.meta || "";
                        cell.text = undefined;
                        cell.component = GenericTableFadePreviewComponent;
                        cell.inputs = {
                            cell: {
                                text: text,
                            },
                        };
                    } else if (column.id === "detail") {
                        let text = error.detail;
                        cell.text = undefined;
                        cell.component = GenericTableFadePreviewComponent;
                        cell.inputs = {
                            cell: {
                                text: text,
                            },
                        };
                    } else if (column.id === "createDt") {
                        cell.sortValue = error[column.id];
                    }

                    cells[column.id] = cell;
                }
                rows.push({
                    id: idx,
                    name: "" + idx,
                    cells: cells,
                });
            });
        }

        return {
            rows: rows,
            totalRows: S25Util.parseInt(errors?.length ?? 0),
        };
    };

    // Adds zero-width spaces after underscores to help with breaking up text for the table
    addBreakpoints(str: string) {
        if (!str) return str;
        return String(str).replace(/_/g, "_\u200B");
    }
}
